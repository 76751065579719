<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#68D389  "
        spinner="bar-fade-scale"
      />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #ffffff">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #000">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap pt-10 justify-end>
        <v-flex xs12 sm6 md3 lg3 xl3 align-self-end>
          <v-dialog v-model="dialog" scrollable max-width="70%">
            <template v-slot:activator="{ on, attrs }">
              <!-- <span 
                v-if="sliderData.length > 0"
              > -->
              <v-btn color="#68D389" dark v-bind="attrs" v-on="on">
                Add More
              </v-btn>
            </template>
            <v-card class="pa-4" height="100%">
              <v-layout wrap>
                <v-flex xs12 md12>
                  <v-layout wrap justify-center>
                    <v-flex xs12 md12 pt-4 text-left>
                      <span class="textField1">Please Add the Text </span>
                      <vue-editor
                        class="textField2 pt-1"
                        v-model="content"
                      ></vue-editor>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex md12 pt-4>
                  <v-layout justify-end>
                    <v-flex md3>
                      <v-btn
                        block
                        tile
                        dark
                        color="#68D389"
                        light
                        :ripple="false"
                        depressed
                        @click="validateInput"
                        class="itemValue"
                      >
                        <span>Save </span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-dialog>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-start>
        <v-flex xs12 sm6 md3 xl4 v-for="(item, i) in sliderData" :key="i" pa-4>
          <v-card tile>
            <v-layout wrap justify-center pt-5>
              <v-flex xs12 px-5 :style="{ height: '60px' }">
                  <span style="font-family: poppinsRegular" v-html="item.content">
                  </span>
              </v-flex>
              <v-flex xs12 py-10>
                <v-layout wrap justify-center pt-10>
                  <v-flex xs6 pa-4>
                    <v-btn
                      small
                      class="mr-2"
                      outlined
                      block
                      color="success"
                      @click="editSlider(item)"
                    >
                      Edit
                    </v-btn>
                  </v-flex>
                  <v-flex xs6 pa-4>
                    <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          style="font-family: poppinsmedium"
                          class="mr-2"
                          outlined
                          block
                          v-on="on"
                          v-bind="attrs"
                          color="red"
                        >
                          Delete
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this
                          Carousel?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <v-dialog v-model="editdialog" scrollable max-width="70%">
        <v-card class="pa-4" height="100%">
          <v-layout wrap>
            <v-flex xs12 md12>
              <v-layout wrap justify-center>
                <v-flex xs12 md12 pt-4 text-left>
                  <span class="textField1">Please Add the Text </span>
                  <vue-editor
                    class="textField2 pt-1"
                    v-model="editingitem.content"
                  ></vue-editor>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex md12 pt-4>
              <v-layout justify-end>
                <v-flex md3>
                  <v-btn
                    block
                    tile
                    dark
                    color="#68D389"
                    light
                    :ripple="false"
                    depressed
                    @click="edit(editingitem._id)"
                    class="itemValue"
                  >
                    <span>Save Changes</span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
  import { VueEditor } from "vue2-editor";
  import axios from "axios";
  export default {
    components: {
      VueEditor,
    },
    data() {
      return {
        sliderData: [],
        Text: null,
        content:"",
        id: null,
        valid: false,
        editingitem: [],
        editdialog: false,
        imgId: null,
  
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        dialogm1: "",
        dialog: false,
        sliderImage: null,
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      validateInput() {
        if (!this.content) {
          this.msg = "Please Provide Text";
          this.showSnackBar = true;
          return;
        } else {
          this.addsliderData();
        }
      },
      getData() {
        this.appLoading = true;
        axios({
          method: "post",
          url: "/carosel/list",
          headers: {
            token: localStorage.getItem("token"),
          },
        //   params: {
        //     type: "Text",
        //   },
        })
          .then((response) => {
            this.appLoading = false;
            this.sliderData = response.data.caroselText;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      addsliderData() {
        var data = {};
        data["content"] = this.content;
        axios({
          method: "post",
          url: "/carousel/add/text",
          data: data,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.msg = "Added Successully";
              this.Text = null;
              this.dialog = false;
              this.showSnackBar = true;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      },
      edit() {
        this.appLoading = true;
        var user = {};
        user["content"] = this.editingitem.content;
        user["id"] = this.editingitem;
        axios({
          method: "POST",
          url: "/carousel/edit/text",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: user,
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.editdialog = false;
            } else {
              this.msg = "Can't Update";
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      deleteItem(r) {
        var data = {};
        data["id"] = r._id;
        axios({
          url: "/carosel/text/remove",
          method: "POST",
          data: data,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.delete = false;
            this.appLoading = false;
            if (response.data.status) {
              this.msg = "Delete Sucessfully";
              this.showsnackbar = true;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      editSlider(item) {
        this.editingitem = item;
        this.editdialog = true;
      },
    },
  };
  </script>
  <style>
  .text {
    text-align: left;
    font-family: poppinsRegular;
    font-size: 14px;
    letter-spacing: 0px;
    color: #9e9e9e;
  }
  </style>