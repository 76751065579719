<template>
    <div>
      <v-layout wrap justify-start>
        <v-flex xs12 text-left>
          <span style="font-size: 22px; font-family: poppinssemibold"
            >Home Carousel</span
          >
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center>
        <v-flex xs12 lg11>
          <v-layout wrap justify-space-between py-2>
            <v-flex v-for="(item, i) in navItems" :key="i" px-2>
              <router-link :to="'/homeSlider?content=' + item.name">
                <v-btn
                  block
                  text
                  :ripple="false"
                  :style="{
                    border:
                      $route.query.content == item.name
                        ? '1px solid #ffe200'
                        : !$route.query.content &&
                          item.name == 'Upload Image'
                        ? '1px solid #ffe200'
                        : 'none',
                  }"
                >
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 14px;
                      color: #343434;
                      text-transform: none;
                    "
                  >
                    {{ item.name }}
                  </span>
                </v-btn>
              </router-link>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <HomeImage
            v-if="$route.query.content == 'Upload Image'|| !$route.query.content"
          />
           <HomeText
            v-if="$route.query.content == 'Upload Text'"
          />
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import HomeImage from "./homeImage";
  import HomeText from "./homeText";
  export default {
    components: {
      HomeImage,
      HomeText
      },
    data() {
      return {
        sliderData: [],
         navItems: [
          { name: "Upload Image" },
          {
            name: "Upload Text"
          },
        ],
      };
    },
  };
  </script>
  <style>
  .text {
    text-align: left;
    font-family: poppinsRegular;
    font-size: 14px;
    letter-spacing: 0px;
    color: #9e9e9e;
  }
  </style>